$imagesPath: "../images";
$black: #000000;
$white: #ffffff;
$light: #C9C9C9;
$dark: #333333;
$gray: #555555;
$silver: #888888;
$green: #00d1b2;
$red: #D10000;
$headline_font: 'Montserrat';
$basic_font: 'Raleway';
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$pageSwithingTime: 0.5s;
$standardMeasure: 15px;
