/**
 * SECTIONS BASIC STYLES
 */

section,
.section {
    max-width: 100%;
    overflow: hidden;
}

section .section--basic,
.section .section--basic {
    padding: 80px 0;
    &.section--darker {
        background: rgba($black, .08)
    }
    .headline {
        text-align: center;
        margin-bottom: 60px;
        h2 {
            text-transform: uppercase;
            font-weight: bold;
        }
        &--left {
            text-align: left;
        }
        &--no-margin-bottom {
            margin-bottom: 0px;
        }
    }
    &.--nopadding-bottom {
        padding-bottom: 0px;
    }
}

.space-from-topbar {
    padding-top: 70px;
}

.tight-container {
    font-size: 16px;
    width: 750px;
    margin: 0 auto;
}

@media all and (max-width: 800px) {
    .tight-container{
        width: auto;
        font-size: inherit;
    }
}


/**
 * ABOUT ME
 */

.about-me-text {

    h3 {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1.8em;
        letter-spacing: 3px;
    }
    .buttons-group {
        margin-top: 30px;
    }
}

.about-me-photo {
    background-repeat: no-repeat;
    background-position: left 10% bottom;
    background-size: 40% auto;
}

.Skill__head{
    font-family: $headline_font;
}


@media all and (max-width: 767px) {

    .about-me{
        margin-bottom: 2 * $standardMeasure;
    }
}

@media all and (max-width: 1200px) {
    .about-me-photo{
        background: transparent;
        background-image: none!important;
    }
}


/**
 * SERVICES
 */

.offer-box:before,
.offer-box:after,
.offer-wrap:before,
.offer-wrap:after {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    border-color: $black;
    border-width: 0px;
    border-style: solid;
    @include transition(all 0.3s ease-in-out);
}

.offer-box {
    text-align: center;
    cursor: default;
    i {
        font-size: 48px;
        margin-bottom: 20px;
    }
    h4, .headline {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 2em;
    }
    &:before {
        bottom: 0px;
        left: 0px;
        border-left-width: 1px;
        border-bottom-width: 1px;
    }
    &:after {
        bottom: 0px;
        right: 0px;
        border-bottom-width: 1px;
        border-right-width: 1px;
    }
}

.offer-wrap {
    position: relative;
    padding: 30px 10px;
    &:before {
        top: 0px;
        left: 0px;
        border-top-width: 1px;
        border-left-width: 1px;
    }
    &:after {
        top: 0px;
        right: 0px;
        border-top-width: 1px;
        border-right-width: 1px;
    }
    &:hover {
        .offer-box:before,
        .offer-box:after,
        &:before,
        &:after {
            height: 40px;
            width: 40px;
        }
    }
}


/**
 * PORTFOLIO
 */


.Portfolio {
    &__items{
        display: flex;
        flex-wrap: wrap;
        display: -webkit-flex;
    }
    &--spacing{
        .Portfolio{
            &__items{
                padding: $standardMeasure;
                .Portfolio-item{
                    margin: $standardMeasure;
                    max-width: calc(25% - #{2*$standardMeasure});
                    flex: 1 1 calc(25% - #{2*$standardMeasure});
                }
            }
        }
    }
}

.Portfolio__nav {
    margin-bottom: 40px;
    ul {
        padding-left: 0px;
            display: flex;
            display: -webkit-flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            justify-content: center;
            list-style-type: none;
        li {
            font-family: $headline_font;
            letter-spacing: 2px;
            font-size: 12px;
            padding: 10px 15px;
            position: relative;
            text-transform: uppercase;
            margin: 10px;
                cursor: pointer;
            &:after {
                @include transition(all 0.3s cubic-bezier(1.0, 0.5, 0.8, 1.0));
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0;
                height: 1px;
                width: 0px;
                background: $black;
            }
            &.active {
                color: $black;
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

.Portfolio-item{
    max-width: 25%;
    height: auto;
        overflow: hidden;
        position: relative;
        &__name{
            color: $white;
            position: relative;
            z-index: 1000;
            opacity: 0;
            transition: 0.5s all ease-in-out;
            font-size: 1.5em;
        }
        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            display: -webkit-flex;
            -webkit-flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            &:before, &:after{
                left:0;
                top: 0;
                content: '';
                opacity: .2;
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.3);
                transition: all .6s ease-in-out;
            }

            &:before{
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
            }
    
            &:after{
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
            &:hover{
                &:before, &:after{
                    opacity: 1;
                    z-index: 10;
                    -webkit-transform: translateX(0) skewX(45deg) scale(2);
                    transform: translateX(0) skewX(45deg) scale(2);
                }
                .Portfolio-item__name{
                    opacity: 1;
                }
            }
        }
        img{
            width: 100%;
        }
    }

    @media all and (max-width: 767px) {
        .Portfolio-item{
            width: 50%;
        }

        .Portfolio--spacing{
                .Portfolio{
                    &__items{
                        padding: $standardMeasure;
                        .Portfolio-item{
                            margin: $standardMeasure;
                            max-width: calc(50% - #{2*$standardMeasure});
                            flex: 1 1 calc(50% - #{2*$standardMeasure});
                        }
                    }
                }
            }
        
    }

    @media all and (max-width: 575px) {

        .Portfolio--spacing{
            .Portfolio{
                &__items{
                    padding: $standardMeasure;
                    .Portfolio-item{
                        margin-bottom: $standardMeasure;
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
        .Portfolio{
             &__items{
                  padding: $standardMeasure;
                  .Portfolio-item{
                      margin-bottom: $standardMeasure;
                      max-width: 100%;
                      width: 100%;
                  }
             }
        }
    }




/**
 * PROJECT PAGE
 */

.project-page {
    .project-image {
        margin-bottom: 50px;
        img {
            width: 100%;
        }
    }
    .project-description {
        font-size: 16px;
    }
    .project-details{
        margin-bottom: 2 * $standardMeasure;
        h4{
            margin-top: 5px;
        }
    }
    .project-detail {
        margin-top: 20px;
        .project-detail-label {
            color: $gray;
            text-transform: uppercase;
            font-size: 0.9em;
        }
        .project-detail-value{
            font-weight:bold;
        }
    }
    .project-naviagtion {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        font-size: 26px;
        a {
            margin: 0 60px;
            text-align: center;
        }
    }
}


@media all and (max-width: 767px) {
    .project-page {
        .project-description {
            font-size: 14px;
        }
    }
}


/**
 * COUNTERS
 */

.paralax-counters {
    background: $black no-repeat fixed 50% 50% / cover;
    position: relative;
    &:after {
        z-index: 10;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        @include overlay($black);
    }
}

.Counter {
    color: $white;
    text-align: center;
    position: relative;
    z-index: 20;
    &__icon {
        font-size: 24px;
    }
    &__number {
        font-size: 48px;
        font-weight: bold;
        margin: 10px 0;
    }
    &__text {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: $white;
    }
}

@media all and (max-width: 767px) {
    .Counter:nth-of-type(1),
    .Counter:nth-of-type(2) {
        margin-bottom: 30px;
    }
}


/**
 * Blog
 */

.Blog {
    &__post {
        position: relative;
        overflow: hidden;
        @include transition(all 0.3s ease-out);
        &:after {
            content: '';
            z-index: 10;
            position: absolute;
            width: 100%;
            height: 100%;
            @include overlay($black, 0.3);
            top: 0;
            left: 0;
            transform: translateY(0);
            @include transition(all 0.6s cubic-bezier(1.0, 0.5, 0.8, 1.0));
        }
        &:hover {
            z-index: 10;
            transform: scale(1.1);
            &:after {
                opacity: 0;
            }
            img {
                filter: grayscale(75%);
            }
            .Blog__post-caption {
                opacity: 1;
                transition-delay: 0.4s;
            }
        }
    }
    &__post-image {
        a {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            z-index: 15;
        }
        img {
            width: 100%;
            height: auto;
            filter: grayscale(100%);
            @include transition(all 0.6s ease-out);
        }
    }
    &__post-caption {
        background: rgba($black, 0.4);
        z-index: 20;
        opacity: 0;
        color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px 30px;
        @include transition(all 0.4s cubic-bezier(1.0, 0.5, 0.8, 1.0));
        h4 {
            font-size: 16px;
            letter-spacing: 2px;
            color: $white;
            text-transform: uppercase;
            line-height: 1.5em;
        }
    }
    &__post-categories {
        @include transition(all 0.4s ease-in-out);
        margin-top: 20px;
        list-style-type: none;
        padding-left: 0px;
        &:before {
            content: '';
            width: 30px;
            height: 1px;
            vertical-align: middle;
            background: $light;
            display: inline-block;
            margin-right: 15px;
        }
        li {
            display: inline;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-left: 3px;
            color: $light;
        }
    }
}

@media all and (max-width: 767px) {
    .Blog {
        &__post {
            margin-bottom: 10px;
            z-index: 10;
            &:after {
                opacity: 0;
            }
            .Blog__post-caption {
                opacity: 1;
            }
            &:hover {
                transform: inherit;
            }
        }
    }
}


/**
 * BLOG POST
 */

.blog-post-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    img {
        max-width: 100%;
    }
}

.blog-post-meta-detail {
    text-transform: uppercase;
    font-weight: bold;
}

.NavLinks {
    width: 750px;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    &__link {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 10px 50px;
        &.--previous {
            align-items: flex-end;
            border-right: 1px solid $black;
            text-align: right;
        }
        &.--next {
            align-items: flex-start;
            border-left: 1px solid $black;
        }
    }
    &__label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
    &__title {
        font-size: 22px;
        a {
            color: $black;
        }
    }
}

@media all and (max-width: 767px) {
    .blog-post-text,
    .NavLinks {
        width: auto;
        &__title{
            font-size:16px;
        }
        &__link{
            &.--next{
                padding: 10px 0 10px 20px;
            }
            &.--previous{
                padding: 10px 20px 10px 0;
            }
        }
    }
}


/**
 * TESTIMONIALS
 */

.section.paralax-testimonials {
    background: $black no-repeat fixed 50% 50% / cover;
    position: relative;
    padding: 100px 0 80px 0;
    &:after {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        @include overlay($black);
    }
}

.Testimonials {
    .owl-dots {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .owl-dot {
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid $white;
                margin: 5px;
                display: inline-block;
                @include transition(0.5s ease-in-out);
            }
            &.active {
                span {
                    background: $white;
                }
            }
        }
    }
}

.Testimonial {
    color: $white;
    &__author {
        text-transform: uppercase;
        margin-right: 40px;
        color: $light;
        &:before {
            background-color: $light;
        }
    }
    &__body {
        font-size: 16px;
        p{
            color: $white;
        }
    }
    i {
        font-size: 48px;
        margin-bottom: 10px;
    }
}


/**
 * CONTACT
 */

.Contact {
    &__details {}
    &__detail {
        margin-top: 20px;
        display: flex;
        align-items: center;
        i {
            font-size: 1em;
            width: 50px;
            height: 50px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $black;
            color: $white;
        }
    }
}

@media all and (max-width: 767px) {
    .Contact h4 {
        margin-top: 50px;
    }
}


/**
 * FOOTER
 */

footer {
    text-align: center;
    color: $light;
    background: $black;
    padding: 60px 0;
    .footer-contact {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        i {
            font-size: 18px;
            margin-right: 15px;
        }
    }
    .copyrights {
        font-family: $headline_font;
        font-size: 10px;
        letter-spacing: 2px;
        margin-top: 10px;
    }
    .footer-socials {
        text-align: right;
        .fa {
            font-size: 22px;
            margin-left: 5px;
        }
        a {
            color: inherit;
        }
    }
}

@media all and (max-width: 767px) {
    footer {
        .footer-contact {
            justify-content: center;
            i {
                margin-right: 5px;
            }
        }
    }
}

@media all and (max-width: 991px) {
    .offer-wrap {
        margin-bottom: 30px;
    }
}

@media all and (max-width: 767px) {
    section .section--basic,
    .section .section--basic {
        padding: 60px 0;
        .headline {
            margin-bottom: 60px;
            h2 {
                text-transform: uppercase;
                font-weight: bold;
            }
            &--no-margin-bottom {
                margin-bottom: 0px;
            }
        }
    }
    .Intro {
        .IntroTyping {
            &__body {
                font-size: 2em;
            }
        }
    }
}
